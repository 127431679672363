<template>
  <b-container fluid>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <b-btn
            variant="outline-secondary"
            :size="'sm'"
            class="mx-auto"
            @click="handleShowDetailsClick()"
            >{{ reportButtonCaption }}</b-btn
          >&nbsp;
          <b-btn
            variant="outline-secondary"
            :size="'sm'"
            style="margin: auto;"
            v-if="isComputedAndMeasured"
            v-b-modal.mashup_modal
            >Rediger mashup</b-btn
          >
        </b-col>
      </b-row>
    </b-container>
    <br />
    <b-container v-if="isCommonProgressVisible">
      <font-awesome-icon icon="spinner" size="4x" spin />
    </b-container>
    <b-container v-if="isCommonProgressHidden">
      <b-row class="border-top .pb-3">&nbsp;</b-row>
      <b-row>
        <b-col md="8" class="text-right">Rapporter i flere år:</b-col>
        <b-col md="4"
          ><VueSlider
            v-model="yearsCount"
            :min="1"
            :max="10"
            :interval="1"
            :lazy="true"
            :marks="true"
        /></b-col>
      </b-row>
    </b-container>
    <b-container v-if="showDetails">
      <b-container
        :key="currentReport.year"
        v-for="currentReport in currentReports"
      >
        <b-row class="border-bottom .pb-3" style="margin-bottom: 10px;"
          >&nbsp;</b-row
        >
        <b-row>
          <b-col md="2" class="text-left pl-1 py-1"
            ><h5>Rapport for {{ currentReport.year }}</h5></b-col
          >
          <b-col
            md="1 text-left"
            v-if="
              currentReport &&
                (currentReport.entries != null ||
                  currentReport.status !== loadingStatus)
            "
          >
            <b-btn
              variant="light"
              :size="'sm'"
              class="mx-auto"
              @click="handleRefreshReportClick(currentReport.year)"
              ><font-awesome-icon icon="sync"
            /></b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-container
            v-if="
              !currentReport ||
                (currentReport.entries == null &&
                  (currentReport.status == null ||
                    currentReport.status === loadingStatus))
            "
          >
            <font-awesome-icon icon="spinner" size="4x" spin />
          </b-container>
          <b-container
            v-if="
              !currentReport ||
                (currentReport.entries == null &&
                  currentReport.status != null &&
                  currentReport.status !== loadingStatus)
            "
          >
            <b-row>
              <b-col md="4" class="text-right"
                ><font-awesome-icon icon="exclamation-circle" size="4x"
              /></b-col>
              <b-col md="4" class="text-left" style="padding-top:20px"
                >Noe gikk galt</b-col
              >
            </b-row>
          </b-container>
        </b-row>
        <b-row>
          <table
            class="table table-striped table-sm table-light"
            v-if="currentReport && currentReport.entries != null"
          >
            <thead>
              <tr>
                <th style="width: 180px"></th>
                <th class="text-right">Jan</th>
                <th class="text-right">Febr</th>
                <th class="text-right">Mars</th>
                <th class="text-right">April</th>
                <th class="text-right">Mai</th>
                <th class="text-right">Juni</th>
                <th class="text-right">Juli</th>
                <th class="text-right">Aug</th>
                <th class="text-right">Sept</th>
                <th class="text-right">Okt</th>
                <th class="text-right">Nov</th>
                <th class="text-right">Des</th>
                <th class="text-right" style="width: 120px">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left"><b>Mengde, m&sup3;</b></td>
                <td
                  class="text-right"
                  :key="entry.month"
                  v-for="entry in currentReport.entries"
                >
                  {{ Math.round(entry.volume) }}
                </td>
                <td class="text-right">
                  <b>{{ overflowsTotalVolume(currentReport) }}</b>
                </td>
              </tr>
              <tr>
                <td class="text-left"><b>Antall</b></td>
                <td
                  class="text-right"
                  :key="entry.month"
                  v-for="entry in currentReport.entries"
                >
                  {{ entry.eventCount }}
                </td>
                <td class="text-right">
                  <b>{{ overflowsTotalEventCount(currentReport) }}</b>
                </td>
              </tr>
              <tr>
                <td class="text-left"><b>Timer</b></td>
                <td
                  class="text-right"
                  :key="entry.month"
                  v-for="entry in currentReport.entries"
                >
                  {{ Math.round(entry.lengthInHours * 100) / 100 }}
                </td>
                <td class="text-right">
                  <b>{{
                    Math.round(
                      overflowsTotalLengthInHours(currentReport) * 100
                    ) / 100
                  }}</b>
                </td>
              </tr>
              <tr>
                <td class="text-left"><b>kgP i overløp</b></td>
                <td
                  class="text-right"
                  :key="entry.month"
                  v-for="entry in currentReport.entries"
                >
                  {{ Math.round(entry.volume * getFactorX() * 100) / 100 }}
                </td>
                <td class="text-right">
                  <b>{{
                    Math.round(
                      overflowsTotalVolume(currentReport) * getFactorX() * 100
                    ) / 100
                  }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </b-container>
    </b-container>
    <b-row style="height: 500px">&nbsp;</b-row>
  </b-container>
</template>

<script>
import { LOADING, SUCCESS } from '../../../store/actions/backend.js'
import {
  REQUEST_OVERFLOW_YEAR_REPORT,
  CLEAR_OVERFLOW_YEAR_REPORT
} from '../../../store/action-types'
import { mapGetters, mapActions } from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
// import report from '../../store/modules/report'

function caclulateSum (entries, prop) {
  return entries
    ? entries.map(entry => entry[prop]).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    : 0
}

export default {
  name: 'reports-view',
  components: {
    VueSlider
  },
  computed: {
    ...mapGetters([
      'weirInfos',
      'year_report_status',
      'measurements',
      'phosphorQuantity'
    ]),

    loadingStatus: function () {
      return LOADING
    },

    isComputedAndMeasured: function () {
      return this.measurements && this.measurements.logger.type.toLowerCase() === 'computed with measured'
    },

    currentReports: function () {
      return this.currentWeir && this.currentWeir.reports
        ? this.currentWeir.reports.slice(0, this.yearsCount).sort((lhs, rhs) => -(lhs.year - rhs.year))
        : []
    },

    isCommonProgressVisible: function () {
      return this.showDetails && this.year_report_status === LOADING
    },

    isCommonProgressHidden: function () {
      return this.showDetails && !(this.year_report_status === LOADING)
    },

    reportButtonCaption: function () {
      return this.showDetails ? 'Lukk rapport' : 'Vis rapport'
    },

    currentWeir: function () {
      let loggerId = Number(this.$route.params.id)
      return this.weirInfos.find(w => w.logger.id === loggerId)
    }
  },

  data () {
    return {
      showDetails: false,
      yearsCount: 0,
      fullCurrentReports: null,
      currentYear: new Date().getFullYear()
    }
  },

  methods: {
    ...mapActions({
      clearYearReport: CLEAR_OVERFLOW_YEAR_REPORT,
      loadYearReport: REQUEST_OVERFLOW_YEAR_REPORT
    }),

    handleShowDetailsClick: function () {
      if (this.yearsCount === 0) {
        this.yearsCount = 1
      }

      this.showDetails = !this.showDetails
    },

    handleRefreshReportClick: function (year) {
      this.loadYearReport({
        loggerId: Number(this.$route.params.id),
        year: year
      })
    },

    getFactorX () {
      if (this.currentWeir && this.currentWeir.logger && this.currentWeir.logger.phosphorQuantity != null) {
        return this.currentWeir.logger.phosphorQuantity / 1000
      }

      return this.phosphorQuantity / 1000
    },

    overflowsTotalVolume: function (currentReport) {
      return caclulateSum(currentReport.entries, 'volume')
    },

    overflowsTotalEventCount: function (currentReport) {
      return caclulateSum(currentReport.entries, 'eventCount')
    },

    overflowsTotalLengthInHours: function (currentReport) {
      return caclulateSum(currentReport.entries, 'lengthInHours')
    },

    overflowsEntries: function (currentReport) {
      return currentReport.entries
    },

    overflowsYear: function (currentReport) {
      return currentReport.year
    }
  },
  created () { 
    var self = this
    setTimeout(function () {
      self.loadYearReport({
        loggerId: Number(self.$route.params.id),
        year: self.currentYear
      })
      }, 2000)
  },
  watch: {
    yearsCount: function () {
      if (this.currentWeir && this.currentWeir.reports && this.yearsCount < this.currentWeir.reports.length) {
        // this.yearsCount = this.fullCurrentReports.length
      } else {
        for (let index = 0; index < this.yearsCount; index++) {
          let year = this.currentYear - index
          let yearReport = this.currentWeir && this.currentWeir.reports ? this.currentWeir.reports.find(r => r.year === year && r.status === SUCCESS) : null
          if (!yearReport) {
            this.loadYearReport({
              loggerId: Number(this.$route.params.id),
              year: year
            })
          }
        }
      }
    }
  }
}
</script>

<style>
.report_header [class*='col'] {
  padding-bottom: 5px;
  /* background-color: #f1f1f1; */
  border-bottom: 1px solid #4f4f4f;
}

.report_odd_row [class*='col'] {
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px solid #8f8f8f;
}

.report_row [class*='col'] {
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #8f8f8f;
}
</style>
