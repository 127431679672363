<template>
  <b-container fluid>
    <b-row>
      <b-col md="6" class="text-left">
        <b-btn
          variant="outline-secondary"
          :size="'sm'"
          style="margin: auto"
          @click="goBack()"
          ><font-awesome-icon icon="arrow-left" />&nbsp;Tilbake</b-btn
        >
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <div
          class="mx-auto img-circle center"
          style="width: 130px; height: 130px"
        >
          <font-awesome-icon
            icon="file-alt"
            size="6x"
            style="margin-left: 30px"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <h4>Overløpsrapport</h4>
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <b-form-select
          v-model="yearSelected"
          :options="yearOptions"
          style="display: inline; max-width: 240px"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <b-form-radio-group
          id="btn_report_type"
          buttons
          size="sm"
          v-model="selectedReportType"
          :options="reportTypeOptions"
          button-variant="outline-lightgray"
          name="radioBtnReportFormat"
        />
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <b-form-radio-group
          id="btn_report_format"
          buttons
          size="sm"
          v-model="selectedReportFormat"
          :options="reportFormatOptions"
          button-variant="outline-lightgray"
          name="radioBtnReportFormat"
        />
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <b-form-radio-group
          id="btnradios3"
          stacked
          buttons
          size="sm"
          v-model="selectedReportContaint"
          :options="reportConstraintOptions"
          button-variant="outline-lightgray"
          class="btn-block"
          name="radioBtnStacked"
          style="max-width: 240px"
        />
      </b-col>
    </b-row>
    <b-row v-if="!reportForAll" class="report_page_row">
      <b-col md="4" lg="3" class="mx-auto text-left">
        <b-card bg-variant="light">
          <VuePerfectScrollbar
            :settings="scroll_settings"
            class="report_page_wrapper"
          >
            <b-form-checkbox-group
              variant="secondary"
              v-model="selectedWeirIds"
              stacked
              :options="weirOptions"
            />
          </VuePerfectScrollbar>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <b-btn
          variant="secondary"
          class="btn-block btn-blue"
          v-bind:disabled="isLoading"
          style="display: inline; max-width: 240px"
          @click="handleReportClick()"
        >
          <font-awesome-icon v-if="isLoading" icon="spinner" spin /><span
            v-if="!isLoading"
            >Opprett</span
          >
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { LOADING } from '../../../store/actions/backend.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { REQUEST_REPORT } from '../../../store/action-types'
import { mapGetters, mapActions } from 'vuex'

const REPORT_FORMAT_PDF = 'pdf'
const REPORT_FORMAT_CSV = 'csv'

const REPORT_DETAIL = 'detail'
const REPORT_BRIEF = 'brief'

const REPORT_CONSTRAINT_ALL_WEIRS = 'all_weirs'
const REPORT_CONSTRAINT_SELECTED_WEIRS = 'selected_weirs'

export default {
  name: 'report-form',
  components: {
    VuePerfectScrollbar
  },
  computed: {
    ...mapGetters(['weirInfos', 'report_status', 'year']),
    reportForAll: function () {
      return this.selectedReportContaint === REPORT_CONSTRAINT_ALL_WEIRS
    },

    effectiveWeirIds: function () {
      return this.reportForAll
        ? this.weirInfos.map(element => element.logger.id)
        : this.selectedWeirIds
    },

    isLoading: function () {
      return this.report_status === LOADING
    }
  },

  data () {
    return {
      weirOptions: [],
      selectedWeirIds: [],
      selectedReportContaint: REPORT_CONSTRAINT_ALL_WEIRS,
      reportConstraintOptions: [
        { text: 'For alle', value: REPORT_CONSTRAINT_ALL_WEIRS },
        { text: 'For utvalg', value: REPORT_CONSTRAINT_SELECTED_WEIRS }
      ],
      reportFormatOptions: [
        { text: 'CSV', value: REPORT_FORMAT_CSV },
        { text: 'PDF', value: REPORT_FORMAT_PDF }
      ],
      reportTypeOptions: [
        { text: 'HOVED', value: REPORT_BRIEF },
        { text: 'DETALJERT', value: REPORT_DETAIL }
      ],
      selectedReportFormat: REPORT_FORMAT_CSV,
      selectedReportType: REPORT_BRIEF,
      scroll_settings: {
        suppressScrollX: true
      },
      yearSelected: 0,
      yearOptions: []
    }
  },

  methods: {
    ...mapActions({
      loadReport: REQUEST_REPORT
    }),
    hasHistory: function () {
      return this.$router.length > 1
    }, // `// window.history.length > 1 },
    goBack: function () {
      if (this.hasHistory()) {
        this.$router.go(-1)
      } else {
        this.$router.push('/weir')
      }
    },
    handleReportClick: function () {
      if (this.isLoading || !this.weirInfos) {
        return
      }

      this.loadReport({
        loggerIds: this.effectiveWeirIds,
        isPdf: this.selectedReportFormat === REPORT_FORMAT_PDF,
        year: this.yearSelected,
        isBrief: this.selectedReportType === REPORT_BRIEF
      })
    }
  },

  created () {
    if (this.weirInfos) {
      this.weirOptions = this.weirInfos
        .map(entry => {
          let text = (entry.logger.alias != null ? entry.logger.alias + ' - ' + entry.logger.system : entry.logger.name) + ''
          if (entry.logger.location && entry.logger.location.length > 0) {
            text += ' (' + entry.logger.location + ')'
          }

          return { text: text, value: entry.logger.id, logger: entry.logger }
        })
        .sort((lhs, rhs) => {
          return (lhs.logger.systemOrder ?? 0) - (rhs.logger.systemOrder ?? 0)
        })
    }

    const numberOfLastYears = 10
    const currentYear = new Date().getFullYear()
    let yearStored = this.year
    if (
      isNaN(yearStored) ||
      (yearStored > currentYear && yearStored < currentYear - numberOfLastYears)
    ) {
      yearStored = currentYear
    }

    const yearInfoList = []
    for (
      let year = currentYear;
      year >= currentYear - numberOfLastYears;
      year--
    ) {
      yearInfoList.push({ value: year, text: year + '' })
    }

    this.yearOptions = yearInfoList
    this.yearSelected = yearStored
  }
}
</script>
